<template>
  <div class="floor-style-4">

    <div class="head-wrap clearfix" :style="{borderBottomColor: data.value.title.value.color}">
      <div class="title" @click="$router.pushToTab(data.value.title.value.link.url)">
        <span :style="{ color : data.value.title.value.color }">{{ data.value.title.value.text }}</span>
        <a style="font-size: 16px;color: #999;margin-left: 11px;">更多 &gt;</a>
      </div>
      <div class="tab-wrapper">

        <div v-for="(tab,index) in data.value.tabs"
             :class="['tab',curTabIndex == index && 'tab-active']"
             :style="getTabStyle(index)"
             @mouseover="switchTab(index)"
        >
          {{ tab.title }}
          <span v-if="curTabIndex == index" class="triangle" :style="{borderTopColor:data.value.title.value.color}"></span>
        </div>
      </div>
    </div>

    <div class="body-wrap">
      <div class="left-wrap">
        <el-carousel height="466px" indicator-position="">
          <el-carousel-item v-for="item in data.value.slider" >
            <el-image :src="$img(item.url)" @click="$router.pushToTab(item.link.url)"></el-image>
          </el-carousel-item>
        </el-carousel>
        <div :style="{backgroundColor:data.value.linkBgColor}" class="cat-wrap">
          <template v-for="(link,index) in data.value.links">
            <div class="cat"  @click="$router.pushToTab(link.link)">{{ link.title }}</div>
            <div v-if="index%3 != 2" style="width:1px;height:14px;background-color:#333;float:left;"></div>
          </template>
        </div>
        <div :style="{backgroundColor:data.value.link2BgColor}"
             style="height: 62px;align-items: center;display:flex;justify-content: space-between;padding:0 17px">
          <template v-for="link in data.value.links2">
            <div style="font-size: 18px;color:#333;" @click="$router.pushToTab(link.link)">{{ link.title }} &gt;</div>
          </template>


        </div>
      </div>
      <div class="center-wrap" v-if="data.value.tabs[curTabIndex]">

        <floor-style-4-goods v-model="data.value.tabs[curTabIndex].goods_1" style="margin-left: 0;margin-bottom: 24px;" />

        <div class="item block" style="margin-bottom: 24px;"  @click="$router.pushToTab(data.value.tabs[curTabIndex].block.link.url)">
          <img v-if="data.value.tabs[curTabIndex].block" :src="$img(data.value.tabs[curTabIndex].block.url)">
          <div v-else class="empty"><span>点击上传图片</span></div>
        </div>

        <floor-style-4-goods style="margin-bottom: 24px;"  v-model="data.value.tabs[curTabIndex].goods_2" />
        <floor-style-4-goods v-model="data.value.tabs[curTabIndex].goods_3" style="margin-left: 0" />

        <floor-style-4-goods v-model="data.value.tabs[curTabIndex].goods_4" />
        <floor-style-4-goods v-model="data.value.tabs[curTabIndex].goods_5" />
        <floor-style-4-goods v-model="data.value.tabs[curTabIndex].goods_6" />

      </div>
    </div>


  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import floorStyle4Goods from "./floor-style-4-goods";
export default {
  name: 'floor-style-4',
  components:{floorStyle4Goods},
  props: {
    data: {
      type: Object
    }
  },
  data() {
    return {
      curTabIndex: 0,
    };
  },
  created() {
  },
  computed: {
    ...mapGetters(['defaultGoodsImage'])
  },
  methods: {

    imageError(index) {
      this.data.value.goodsList.value.list[index].sku_image = this.defaultGoodsImage;
    },
    getTabStyle(index) {
      if (index == this.curTabIndex) {
        return {
          backgroundColor: this.data.value.title.value.color,
        }
      }
    },
    switchTab(index) {
      this.curTabIndex = index;
    }
  }
};
</script>

<style lang="scss">


/* 楼层样式四*/
.clearfix:after {
  display: block;
  content: "";
  clear: both;
}


.floor-style-4 .head-wrap {
  margin-bottom: 14px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  position: relative;
}

.floor-style-4 .head-wrap .title {
  float: left;
  line-height: 39px;
  color: #333;
  font-size: 26px;
  cursor: pointer;
}



.floor-style-4 .head-wrap .tab-wrapper{
  position: absolute;
  right: 0;
  bottom: -1px;
}


.floor-style-4 .head-wrap .tab {
  position: relative;
  text-align: center;
  line-height: 1;
  font-size: 16px;
  color: #999999;
  float: left;
  cursor: pointer;
  padding: 11px 22px;
}


.floor-style-4 .head-wrap .tab:hover .tab-action-wrapper {
  display: block;
}

.floor-style-4 .head-wrap .tab.tab-active {
  color: #fff;
}

.floor-style-4 .head-wrap .tab .triangle {
  position: absolute;

  left: 50%;
  margin-left: -5px;
  bottom: -10px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid red;
}

.floor-style-4 .body-wrap {
  height: 614px;
}

.floor-style-4 .body-wrap .left-wrap {
  float: left;
  width: 234px;
  height: 614px;
  cursor: pointer;
  background: #fff;
}

.floor-style-4 .body-wrap .left-wrap div.empty {
  background: #ebf8fd;
  height: 100%;
  text-align: center;
  color: #88c4dc;
  position: relative;
}

.floor-style-4 .body-wrap .left-wrap div.empty span {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  margin-top: -27px;
}

.floor-style-4 .body-wrap .left-wrap img {
  max-width: 100%;
  cursor: pointer;
}

.floor-style-4 .body-wrap .left-wrap .cat-wrap {
  height: 86px;
  background-color: #FFF1F1;
  padding: 0 17px;
  flex-direction: row;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.floor-style-4 .body-wrap .left-wrap .cat {
  font-size: 14px;
  text-align: center;
  width: 66px;
  color: #333;

}

.floor-style-4 .body-wrap .center-wrap {
  margin-left: 15px;
  display: block;
  float: left;
  width: 951px;
}

.floor-style-4 .body-wrap .center-wrap .item {

  background: #fff;
  cursor: pointer;
  height: 295px;
  margin-left: 17px;
  float: left;
  box-sizing: border-box;
}


.floor-style-4 .body-wrap .center-wrap .item:hover {
  z-index: 2;
  -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  -webkit-transform: translate3d(0, -2px, 0);
  transform: translate3d(0, -2px, 0);
}


.floor-style-4 .body-wrap .center-wrap .item.block {
  width: 467px;
}

.floor-style-4 .body-wrap .center-wrap .item.block .empty {
  background: #ebf8fd;
  height: 100%;
  text-align: center;
  color: #88c4dc;
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;

}

.floor-style-4 .body-wrap .center-wrap .item.block img {
  width: 100%;
  height: 100%;
}

.floor-style-4 .body-wrap .center-wrap .goods {
  width: 225px;
  padding: 18px 0;
}

.floor-style-4 .body-wrap .center-wrap .item.empty {
  background: #F5F5F5;
}

/*.js-style-1 .body-wrap .goods-list li:nth-child(4n+1){margin-left: 0;}*/
.floor-style-4 .body-wrap .center-wrap .item .img-wrap {
  width: 150px;
  height: 150px;
  margin: 0 auto 18px;
  text-align: center;
  line-height: 160px;
}

.floor-style-4 .body-wrap .center-wrap .item .img-wrap.empty {
  background: #ebf8fd;
  color: #88c4dc;
}

.floor-style-4 .body-wrap .center-wrap .item .img-wrap img {
  max-width: 100%;
  max-height: 100%;
}

.floor-style-4 .body-wrap .center-wrap .item h3 {
  font-size: 12px;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: 5px 15px;
}


.floor-style-4 .body-wrap .center-wrap .item .price {
  margin: 0 10px 14px;
  text-align: center;
}

.floor-style-4 .bottom-wrap {
  margin-top: 15px;
  width: 1210px;
  height: 118px;
  cursor: pointer;
  overflow: hidden;
}

.floor-style-4 .bottom-wrap div.empty {
  background: #ebf8fd;
  height: 100%;
  text-align: center;
  color: #88c4dc;
  position: relative;
}

.floor-style-4 .bottom-wrap div.empty span {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  margin-top: -27px;
}

.floor-style-4 .bottom-wrap img {
  max-width: 100%;
}


</style>
