<template>
  <div v-if="detail">
    <div :style="`background-color:${carouselBgColor}`">
      <el-carousel height="448px" :autoplay="true" arrow="never" @change="changeCarouselBgColor"
        style="max-width:1920px;min-width:1200px;margin: 0 auto">
        <el-carousel-item v-for="item in detail.slides" :key="item.id">
          <div :style="`background-image:url(${$img(item.image)}); width:100%; height:100%`"
            @click="$router.pushToTab(item.link)">
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>


    <div style="width: 1200px;margin: 0 auto;">
      <div class="" style="margin-top: 20px;justify-content: space-between;display: flex;font-size:0;">

        <el-image v-for="(item, i) in detail.adv" :key="i" :src="$img(item.image)"
          :style="`height: 200px; width: calc(100% / ${detail.adv.length} - 5px);display:inline-block;`"
          @click="e => jump(item.link)" />


      </div>

      <floor :floors="detail.floors"></floor>
    </div>

  </div>
</template>
<script>
import floor from "./floor"
import http from "@/utils/http"

export default {
  name: "channel",
  mixins: [],
  components: {
    floor
  },
  created() {
    // 初始化Id
    this.id = this.$route.params.pathMatch
    this.load()
  },
  watch: {
    $route(newVal) {
      this.id = newVal
      this.load()
    }
  },
  data: () => {
    return {
      id: null,
      detail: null,
      carouselBgColor: null,
    }
  },
  methods: {

    load() {
      return http({
        url: "pc/api/channel/get",
        data: { id: this.id }
      }).then(res => {
        this.detail = res.data
        this.$store.dispatch("channel/update", res.data);
        window.channel = this.detail
        if (this.detail.slides != undefined && this.detail.slides.length > 0) {
          this.carouselBgColor = this.detail.slides[0].background
        }
        this.$forceUpdate()
        console.log(window.channel)
      }).catch(err => {
        this.$message.error(err.message)
      })
    },
    jump(link) {
      location.href = link
    },
    changeCarouselBgColor(index) {
      this.carouselBgColor = this.detail.slides[index].background

    }

  }
}
</script>
<style lang="scss" scoped>
.clearfix:after {
  display: block;
  content: "";
  clear: both;
}
</style>
