const state = {
	detail: null
}

const mutations = {
	UPDATE: (state, v) => {
		state.detail = v
	}
}

const actions = {
	update({
		commit
	}, v) {
		console.log("channel",v)
		return new Promise((resolve, reject) => {
			commit('UPDATE', v)
			resolve();
		})
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}
