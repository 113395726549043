let colors = [
    {
        value: 1,
        title: "红色",
        color: "#e84c3d",
        hoverColor: "#c1392b",
        titleColor: "#e23423",

    },
    {
        value: 2,
        title: "粉色",
        color: "#ff6766",
        hoverColor: "#ff4d4d",
        titleColor: "#ee5352",
    },
    {
        value: 3,
        title: "橘色",
        color: "#ff9326",
        hoverColor: "#fb7d00",
        titleColor: "#fe8610",

    }, {
        value: 4,
        title: "绿色",
        color: "#27ae61",
        hoverColor: "#1d7e46",
        titleColor: "#239854",

    }, {
        value: 5,
        title: "蓝色",

        color: "#3598dc",
        hoverColor: "#2a80b9",
        titleColor: "#2f8ecc",

    }, {
        value: 6,
        title: "紫色",

        color: "#66307a",
        hoverColor: "#482257",
        titleColor: "#562569",

    },
    {
        value: 7,
        title: "褐色",

        color: "#b25900",
        hoverColor: "#8c4600",
        titleColor: "#a15101",

    },
    {
        value: 0,
        title: "默认",

        color: "#f5f5f5",
        hoverColor: "#d7d7d7",
        titleColor: "#ff0000",
    },
]
export default {
    colors,
    getColor(v) {
        let ret = {}
        colors.some(item => {
            if (item.value == v) {
                ret = item;
                return true
            }
        })
        return ret
    }
}