<template>
  <div :class="`shop-list-content shop-list-active ${color.value == 0 ? 'shop-list-content-default':''}`"
       :style="`background-color:${color.color}`">

    <div class="shop-list shop-list-active">
      <div class="list-item" v-for="(item, index) in channel.sub_cats" :key="index"
           @mouseover="selectedCategory = item" @mouseleave="selectedCategory = null"
           :style="selectedCategory && (selectedCategory.category_id == item.category_id) ? `background-color:${color.hoverColor}`:''"
      >
        <router-link
            :to="{ path: '/list', query: { category_id: item.category_id, level: item.level } }"
            target="_blank"
        >
          <div class="list-item-left">
            <p class="category-name">{{ item.category_name }}</p>
          </div>
          <!-- <i class="el-icon-arrow-right" aria-hidden="true" v-if="item.child_list"></i>  -->
        </router-link>
        <div class="item-itm item-itm-img">
          <router-link v-for="(second_item, second_index) in item.child_list" :key="second_index"
                       :to="{ path: '/list', query: { category_id: second_item.category_id, level: second_item.level } }"
                       target="_blank"
                       v-show="second_index < 3"
                       style="display:inline-block;">
            {{ second_item.short_name ? second_item.short_name : second_item.category_name }}
          </router-link>
        </div>
        <div class="cate-part" v-if="item.child_list"
             :class="{ show: selectedCategory && selectedCategory.category_id == item.category_id }">
          <div class="cate-part-col1">
            <div class="cate-detail">
              <dl class="cate-detail-item">
                <dt class="cate-detail-tit">
                  <router-link target="_blank"
                               :to="{ path: '/list', query: { category_id: item.category_id, level: item.level } }">
                    {{ item.category_name }}
                  </router-link>
                </dt>
                <dd class="cate-detail-con">
                  <router-link
                      v-for="(third_item, third_index) in item.child_list"
                      :key="third_index"
                      target="_blank"
                      :to="{ path: '/list', query: { category_id: third_item.category_id, level: third_item.level } }"
                  >
                    <p class="category-name">{{ third_item.category_name }}</p>
                  </router-link>
                </dd>
              </dl>

            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import channel from "@/utils/channel"

export default {
  props: {
    channel: {
      type: Object,
    },
  },
  data() {
    return {
      selectedCategory: -1,
      colors: channel.colors,
    }
  },
  computed: {
    color() {
      let ret = {}
      this.colors.some(item => {
        if (item.value == this.channel.color) {
          ret = item;
          return true
        }
      })
      return ret
    }
  },
  methods: {}
}
</script>

<style scoped lang="scss">

  .header {
    width: 100%;
    height: 178px;
    background-color: #fff;

    .shadow {
      box-shadow: -1px 3px 12px -1px rgba(0, 0, 0, 0.3);
    }

    .border {
      border: 1px solid #f5f5f5;
    }

    .nav.channel-nav {
      width: 1210px;
      height: 41px;
      margin: 0 auto;
      position: relative;
      z-index: 9999;

      .shop-sort {
        width: 200px !important;
        height: 40px;
        color: #fff;
        background-color: $base-color;
        float: left;
        padding: 10px 10px 11px 10px;
        box-sizing: border-box;

        a {
          font-size: 14px;
          line-height: 20px;
          float: left;
          color: #fff;
        }

        i {
          font-size: 14px;
          float: right;
        }
      }

      .shop-list-content {
        width: 200px !important;
        height: 448px;
        position: absolute;
        left: 0;
        top: 40px;
        background-color: rgba(51, 51, 51, 0.82);
        display: none;
        padding: 0;
        box-sizing: border-box;
        font-size: $ns-font-size-base;
        z-index: 10;
        color: rgba(255, 255, 255, 0.59);
        //overflow-x:auto;
        //overflow-y: hidden;
        &::-webkit-scrollbar {
          display: none;
        }

        // overflow: hidden;
        .shop-list {
          width: 100%;
          height: 448px;
          overflow: hidden;
          // box-shadow: -1px 3px 12px -1px rgba(0, 0, 0, 0.3);
          &::-webkit-scrollbar {
            display: none;
          }

          a:hover {
            color: $base-color;
          }

          .list-item {
            box-sizing: border-box;
            height: 74.66px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            // min-height:50px;
            // height: 45px;
            // line-height: 50px;
            padding: 13px;
            //overflow: hidden;
            border-bottom: 1px dashed #f7c0bb;

            a {
              display: flex;
              justify-content: space-between;
              align-items: center;
              white-space: nowrap;
              //overflow: hidden;
              text-overflow: ellipsis;
              // width: 95%;
              // display: block;
              color: #fff;

              > div {
                display: flex;
                align-items: center;
              }
            }

            &:hover {
              // background-color: #000;
              //-webkit-transition: 0.2s ease-in-out;
              //-moz-transition: -webkit-transform 0.2s ease-in-out;
              //-moz-transition: 0.2s ease-in-out;
              //transition: 0.2s ease-in-out;

              a:hover {
                color: #FD274A;
              }
            }

            span:hover {
              color: #FD274A;
            }

            .category-img {
              width: 17px;
              height: 17px;
              margin-right: 10px;
            }

            .category-name {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-weight: normal;
              font-size: 16px;
              line-height: 24px;
            }

            .item-itm {
              font-size: 14px;
              line-height: 24px;
              overflow: hidden;

              a {
                margin-top: 5px;
                margin-right: 10px;
                color: #f7c0bb;

                &:hover {
                  color: #FD274A !important;
                }
              }

              &.item-itm-img {
                // margin-left: 27px;
              }
            }

            .cate-part {
              display: none;
              position: absolute;
              left: 200px !important;
              top: 0;
              z-index: auto;
              // width: 998px;
              width: 762px;
              height: 448px;
              overflow-y: auto;
              border: 1px solid #f7f7f7;
              background-color: #fff;
              -webkit-box-shadow: 2px 0 5px rgba(0, 0, 0, 0.05);
              -moz-box-shadow: 2px 0 5px rgba(0, 0, 0, 0.05);
              box-shadow: 2px 0 5px rgba(0, 0, 0, 0.05);
              -webkit-transition: top 0.25s ease;
              -o-transition: top 0.25s ease;
              -moz-transition: top 0.25s ease;
              transition: top 0.25s ease;

              &.show {
                display: block;
              }

              &::-webkit-scrollbar {
                display: none;
              }
            }

            .cate-part-col1 {
              float: left;
              width: 100%;

              .cate-detail-item {
                position: relative;
                min-height: 36px;
                padding-left: 20px;
                display: inline-block;

                &:last-child {
                  margin-bottom: 30px;
                }

                .cate-detail-tit {
                  margin-top: 30px;
                  margin-right: 20px;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  font-weight: 700;

                  a {
                    display: block;
                    color: #333333;
                  }

                  a:hover {
                    color: #FD274A;
                  }

                }

                .cate-detail-con {
                  overflow: hidden;
                  display: flex;
                  flex-wrap: wrap;
                  // border-top: 1px dashed #eee;
                  a {
                    justify-content: start;
                    font-size: 12px;
                    height: 30px;
                    /* float: left; */
                    margin: 4px 40px 4px 0;
                    white-space: nowrap;
                    line-height: 30px;
                    color: #666;
                    display: flex;
                    box-sizing: border-box;
                    margin-top: 20px;
                  }

                  a:hover {
                    color: #FD274A;
                  }
                }

                &:first-child .cate-detail-con {
                  border-top: none;
                }
              }
            }

            // .sub-class-right {
            // 	display: block;
            // 	width: 240px;
            // 	height: 439px;
            // 	float: right;
            // 	border-left: solid 1px #e6e6e6;
            // 	overflow: hidden;
            // 	.adv-promotions {
            // 		display: block;
            // 		height: 441px;
            // 		margin: -1px 0;
            // 		a {
            // 			background: #fff;
            // 			display: block;
            // 			width: 240px;
            // 			height: 146px;
            // 			border-top: solid 1px #e6e6e6;
            // 			img {
            // 				background: #d3d3d3;
            // 				width: 240px;
            // 				height: 146px;
            // 			}
            // 		}
            // 	}
            // }
          }
        }
      }

      .shop-list-content-default {
        .list-item {
          .category-name {
            color: #333 !important;
          }

          .item-itm a {
            color: #777;
          }
        }
      }

      .shop-list-active {
        display: block;
      }

      nav {
        width: 934px;
        height: 36px;
        float: left;
        font-size: 14px;

        ul {
          margin: 0;
          padding: 0;
          width: 100%;
          height: 100%;

          li {
            cursor: pointer;
            list-style: none;
            float: left;
            padding: 8px 24px;

            a {
              &:hover {
                color: $base-color;
              }
            }
          }

          li:hover {
            color: $base-color;
            font-weight: bold;
          }

          .router-link-active {
            color: $base-color;
            font-weight: bold;
            border-bottom: 2px solid #FD274A;
          }
        }
      }
    }
  }

</style>
