<template>
  <div class="header" v-loading="loading">
    <ns-header-top />
    <ns-header-mid :shop-info="shopInfo" />
    <div class="shop" v-if="shopInfo">
      <div class="shop-wrap" :style="`background-color:${shopInfo.top_bgc}`">
        <div
          :style="`background: url(${$img(shopInfo.top_img)}) no-repeat center;height:115px;width:100%;min-width:1200px;`">
        </div>

      </div>
    </div>
    <div class="shop-header">
      <div class="nav">
        <div class="shop-sort" @mouseover="shopHover" @mouseleave="shopLeave">
          <!-- <router-link to="/category">所有商品分类</router-link> -->
          <router-link :to="'/shop_list?site_id=' + site_id" style="width:100%;text-align: center;">
            全部商品分类
            <i class="iconfont iconweibiaoti35">
            </i>
          </router-link>
        </div>
        <!-- <div class="shop-list" :class="forceExpand || isShopHover || isIndex ? 'shop-list-active' : 'shadow'"
          @mouseover.stop="shopHover" @mouseleave.stop="shopLeave">
          <div :class="['list-item', index == 0 && 'list-item-first']" v-for="(item, index) in goodsCategoryTree"
            :key="index" @mouseover="selectedCategory = item.category_id" @mouseleave="selectedCategory = -1">
            <router-link target="_blank"
              :to="{ path: '/shop_list', query: { site_id: site_id, shop_category_id: item.category_id, category_level: item.level } }">
              {{ item.category_name }}
            </router-link>
            <div class="cate-part" v-if="item.child_list" :class="{ show: selectedCategory == item.category_id }">
              <router-link v-for="(second_item, second_index) in item.child_list" class="item" :to="{
                path: '/shop_list',
                query: { site_id: site_id, shop_category_id: second_item.category_id, category_level: second_item.level }
              }">
                {{ second_item.category_name }}
                <i class="el-icon-arrow-right" aria-hidden="true" v-if="second_item.child_list"></i>
              </router-link>
            </div>
          </div>
        </div> -->
        <div class="shop-list" :class="forceExpand || isShopHover || isIndex ? 'shop-list-active border' : 'shadow'"
          @mouseover.stop="shopHover" @mouseleave.stop="shopLeave">
          <div class="list-item" v-for="(item, index) in goodsCategoryTree" :key="index"
            @mouseover="selectedCategory = item.category_id" @mouseleave="selectedCategory = -1">
            <router-link
              :to="{ path: '/shop_list', query: { site_id: site_id, shop_category_id: item.category_id, category_level: item.level } }">
              {{ item.category_name }}
            </router-link>
            <div class="cate-part" v-if="item.child_list" :class="{ show: selectedCategory == item.category_id }">
              <div class="cate-part-col1">
                <div class="cate-detail">
                  <dl class="cate-detail-item" v-for="(second_item, second_index) in item.child_list" :key="second_index">
                    <dt class="cate-detail-tit">
                      <router-link :to="{
                        path: '/shop_list',
                        query: { site_id: site_id, shop_category_id: second_item.category_id, category_level: second_item.level }
                      }" target="_blank">
                        {{ second_item.category_name }}
                        <i class="el-icon-arrow-right" aria-hidden="true" v-if="second_item.child_list"></i>
                      </router-link>
                    </dt>
                    <dd class="cate-detail-con" v-if="second_item.child_list">
                      <router-link target="_blank" v-for="(third_item, third_index) in second_item.child_list" :key="third_index" :to="{
                        path: '/shop_list',
                        query: { site_id: site_id, shop_category_id: third_item.category_id, category_level: third_item.level }
                      }">
                        {{ third_item.category_name }}
                      </router-link>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
        <nav>
          <ul style="overflow: hidden;">
            <li v-for="nav_item in shopInfo.navs" :key="nav_item.id"
              :class="nav_item.url == navSelect ? 'router-link-active' : ''"
              @click="navUrl(nav_item.link, nav_item.is_blank)">
              <span>{{ nav_item.title }}</span>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import NsHeaderTop from './NsHeaderTop.vue';
import NsHeaderMid from './NsHeaderMid.vue';
import { shopInfo, tree } from '@/api/shop/index';
import { mapGetters } from 'vuex';
import Util from "@/utils/util"

export default {
  props: {
    forceExpand: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isShopHover: false,
      isIndex: false,
      thisRoute: '',
      goodsCategoryTree: [],
      selectedCategory: -1,
      navSelect: '',
      navList: [{ nav_title: '店铺首页', id: '0' }, { nav_title: '全部商品', id: '1' }],
      site_id: '',
      shopInfo: {},
      loading: true
    };
  },
  components: {
    NsHeaderTop,
    NsHeaderMid
  },
  computed: {
    ...mapGetters(['defaultShopImage'])
  },
  beforeCreate() {
  },
  created() {
    if (!this.$route.query.site_id) {
      this.site_id = this.$route.path.replace('/shop-', '');
    } else {
      this.site_id = this.$route.query.site_id;
    }
    this.$store.dispatch('cart/cart_count');
    this.getTree();
    this.isIndex = this.$route.name == 'shop_index';
    this.getShopInfo();
  },
  mounted() {
    // console.log(Util.img(this.shopInfo.top_img))
  },
  watch: {
    $route(curr) {
      if (!this.$route.query.site_id) {
        this.site_id = this.$route.path.replace('/shop-', '');
      } else {
        this.site_id = this.$route.query.site_id;
      }
      this.$store.dispatch('cart/cart_count');
      this.getTree();
      this.isIndex = this.$route.name == 'shop_index';
      this.getShopInfo();
    }
  },
  methods: {
    getTree() {
      tree({ site_id: this.site_id })
        .then(res => {
          this.goodsCategoryTree = res.data;
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    },
    //鼠标移入显示商品分类
    shopHover() {
      if (this.goodsCategoryTree.length) {
        this.isShopHover = true;
      }
    },
    //鼠标移出商品分类隐藏
    shopLeave() {
      if (this.goodsCategoryTree.length) {
        this.isShopHover = false;
      }
    },
    navUrl(url, target) {
      if (!url) return
      if (url.indexOf("http") == -1) {
        if (target) this.$router.pushToTab({ path: url })
        else this.$router.push({ path: url })
      } else {
        if (target) window.open(url)
        else window.location.href = url
      }
    },
    //获取店铺信息
    getShopInfo() {
      shopInfo({ site_id: this.site_id })
        .then(res => {
          if (res.code == 0) {
            this.shopInfo = res.data;
            this.loading = false;
          }
        })
        .catch(err => {
          this.loading = false;
          this.$message.error(err.message);
        });
    }
  }
};
</script>

<style scoped lang="scss">
.header {
  width: 1200px;
  background-color: #fff;
  padding: 0;
  margin: 0;
}

.header-in {
  width: $width;
  height: 109px;
  margin: 20px auto 0;

  img {
    margin: 22px 120px auto 0;
    float: left;
  }

  .in-sousuo {
    width: 550px;
    float: left;
    margin: 10px 0 0 0;

    .sousuo-tag {
      display: block;
      height: 24px;

      span {
        cursor: pointer;
        font-size: 12px;
        padding: 0 10px;
        border-right: solid 1px #e1e1e1;

        &:last-child {
          border-right: none;
        }
      }

      .span-font {
        color: $base-color;
      }
    }

    .sousuo-box {
      width: 100%;
      height: 36px;
      border: 2px solid $base-color;
      box-sizing: border-box;

      input {
        width: 400px;
        height: 22px;
        background: none;
        outline: none;
        border: none;
        float: left;
        margin: 4px;
      }

      .box-btn {
        width: 80px;
        height: 100%;
        background-color: $base-color;
        color: #fff;
        float: right;
        text-align: center;
        line-height: 32px;
        cursor: pointer;
      }
    }

    .sousuo-key {
      width: 100%;
      height: 20px;
      margin-top: 5px;
      font-size: 12px;

      span {
        float: left;
      }

      ul {
        margin: 0;
        padding: 0;
        float: left;
        color: $ns-text-color-black;

        li {
          cursor: pointer;
          list-style: none;
          float: left;
          margin-right: 10px;
          color: $ns-text-color-black;
        }
      }
    }
  }

  .car {
    float: left;
    width: 91px;
    height: 36px;
    margin-top: 34px;
    margin-left: 30px;
    padding: 0 28px 0 19px;
    border: 1px solid #dfdfdf;
    color: $base-color;
    font-size: 12px;

    span {
      cursor: pointer;
      line-height: 38px;
      margin-right: 10px;
    }
  }
}

.shop-header {
  width: 100%;
  background-color: #383838;

  .shadow {
    box-shadow: -1px 3px 12px -1px rgba(0, 0, 0, 0.3);
  }

  .border {
    //border: 1px solid #f5f5f5;
  }

  .nav {
    width: 1200px;
    height: 41px;
    margin: 0 auto;
    position: relative;

    .shop-sort {
      width: 200px;
      height: 41px;
      color: #fff;
      background-color: $base-color;
      float: left;
      padding: 10px 10px 11px 10px;
      box-sizing: border-box;

      a {
        line-height: 20px;
        float: left;
        color: #fff;
        font-size: 15px;
        font-weight: bold;
      }

      i {
        font-size: 14px;
        //float: right;
      }
    }

    .shop-list {

      width: 200px;
      height: 418px;
      position: absolute;
      left: 0;
      top: 41px;
      background-color: rgba(255, 255, 255, 0.9);
      display: none;
      //padding: 12px 0;
      box-sizing: border-box;
      font-size: 14px;
      z-index: 10;
      // box-shadow: -1px 3px 12px -1px rgba(0, 0, 0, 0.3);
      color: rgba(0, 0, 0, 0.34);

      a:hover {
        color: $base-color;
        //background-color: white;
      }

      .list-item {
        line-height: 40px;
        border: 1px solid transparent;
        display: block;
        z-index: 100;
        //position: relative;
        &-first {
          &:hover {
            border-top: 1px solid white !important;
          }
        }

        * {
          box-sizing: border-box;
        }

        >a {

          position: relative;
          display: inline-block;
          text-align: center;
          width: 100%;

          &:hover {
            background-color: white;
            z-index: 100;
          }
        }

        &:hover {
          border: 1px solid #f55053;
          border-right: transparent;
          background-color: white;
          z-index: 100;
        }

        .cate-part {
          display: none;
          position: absolute;
          left: 199px;
          top: 0;
          z-index: 10;
          // width: 998px;
          width: 758px;
          height: 100%;
          background-color: white;
          border: 1px solid #f55053;
          // border-top: 0;

          &.show {
            display: block;
          }

          padding: 20px;

          .item {

            display: inline-block;
            line-height: 40px;
            padding-right: 30px;
          }
          .cate-detail-item{
            display: inline-block;
            padding-right: 30px;
          }
        }

      }
    }

    .shop-list-active {
      display: block;
    }

    nav {
      width: 969px;
      height: 36px;
      float: left;
      font-size: 14px;
      color: #fff;

      ul {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;

        li {
          cursor: pointer;
          list-style: none;
          float: left;
          padding: 8px 20px;

          a {
            &:hover {
              color: $base-color;
            }
          }
        }

        li:hover {
          color: $base-color;
          font-weight: bold;
        }

        .router-link-active {
          color: $base-color;
          font-weight: bold;
        }
      }
    }
  }
}

.shop {
  width: 100%; //相对于.el-宽度（1920-1200），别的地方都限制了1200宽度
  height: 115px;
  background: #f7f8fb;

  .shop-wrap {

    margin: 0 auto;
    //background-color: white;
    display: flex;
    justify-content: center;
    position: relative;

    .banner-bgc {
      width: 1200px;
      height: 115px;
    }
  }

  .banner-content {
    position: absolute;
    z-index: 111;
    left: 0;
    top: 0;
  }

  .img-wrap {
    width: 116px;
    height: 50px;
    margin: 30px 0 38px 110px;
    display: block;
    overflow: hidden;

    img {
      display: inline-block;
      max-height: 73px;
    }
  }

  .shop-title {
    font-size: 28px;
    color: #000000;
    font-weight: 600;
    margin-left: 44px;
  }

  .shop-welcome {
    display: flex;
    margin-left: 320px;
    align-items: center;

    p {
      font-weight: 400;
      font-size: 18px;
      color: #000000;
      margin-left: 31px;
    }
  }
}
</style>
