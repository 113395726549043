<template>
  <div class="item goods" @click="goSku(value.sku_id)">
    <template v-if="value">
      <div class="img-wrap">
        <img alt="商品图片" :src="$img(value.goods_image)">
      </div>
      <h3>{{value.goods_name}}</h3>
      <p class="ns-text-color">&nbsp;</p>
      <p class="price ns-text-color">
        <span class="num">{{value.price}}元</span>
      </p>
    </template>
    <template v-else>
      <div class="img-wrap empty">商品图片</div>
      <h3>商品名称</h3>
      <p class="price ns-text-color">
        <span class="num">99元</span>
      </p>
    </template>
  </div>
</template>

<script>
export default {
  name: "floor-style-4-goods",
  props: {
    value: {
      type: Object,
    },
  },
  data: function () {
    return {};
  },
  methods: {
    goSku(skuId) {
      this.$router.pushToTab('/sku-' + skuId);
    },
  }
}
</script>

<style scoped>

</style>