<template>
        <div>
            <div v-for="(item, index) in floors" :key="index" class="floor_item clearfix">
                <floor-style-1 v-if="item.block_id == 1" :data="item" />
                <floor-style-2 v-if="item.block_id == 2" :data="item" />
                <floor-style-3 v-if="item.block_id == 3" :data="item" />
                <floor-style-4 v-if="item.block_id == 4" :data="item" />
            </div>
        </div>
</template>

<script>

    import floorStyle1 from "@/views/index/components/floor-style-1"
    import floorStyle2 from "@/views/index/components/floor-style-2"
    import floorStyle3 from "@/views/index/components/floor-style-3"
    import floorStyle4 from "@/views/index/components/floor-style-4"


    export default {
        name: "index",
        props:{
          floors:{
              type:Array,
              default:()=>([])
          }
        },
        components:{
            floorStyle1,
            floorStyle2,
            floorStyle3,
            floorStyle4
        },
        data: () => {
            return {
            }
        },
    }

</script>

<style scoped>

</style>