<template>

        <el-container>
            <!-- 头部 -->
            <el-header height="auto" class="header"><shop-header /></el-header>
            <el-main class="content">
                <transition name="slide"><router-view /></transition></el-main>
            <!-- 底部 -->
            <el-footer><ns-footer /></el-footer>
        </el-container>

</template>

<script>
    import ShopHeader from "./components/ShopHeader"
    import NsHeader from "./components/NsHeader"
    import NsFooter from "./components/NsFooter"

    export default {
        created() {

        },
        data: () => {
            return {

            }
        },
        mounted() {
            let visualWidth = window.innerWidth;
            let el=document.querySelector(".el-container")
            window.onresize = () => {
                return (() => {
                    visualWidth=window.innerWidth
                    if(visualWidth>=1200 && visualWidth<=1920){
                        el.style.width='100%'
                    }
                })();
            };
        },
        computed: {},

        watch: {
        },
        components: {
            ShopHeader,
            NsFooter
        }
    }
</script>
<style lang="scss" scoped>
    //权重高了就层叠不掉了
    .el-container{
        margin: 0 auto;
        width: 100%;
    }
    .header {
        width: 100%;
        padding: 0;
        margin: 0 auto;
    }
    .el-main {
        width: 100%;
        margin: 0 auto;
        border-top: none;
    }
    .el-footer {
        width: 100%;
        height: auto !important;
        background-color: #fff;
        padding: 0;
        padding-top: 45px;
    }

    .content {
        padding: 0;
    }
</style>
